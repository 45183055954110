import { CircleLoader } from '@tovala/component-library'
import { OvenLookupData } from 'utils/oatsApi'

const OVEN_LOOKUP_FIELDS = [
  {
    display: 'User ID',
    field: 'userID',
  },
  {
    display: 'Device ID',
    field: 'deviceID',
  },
  {
    display: 'Serial Number',
    field: 'serialNumber',
  },
  {
    display: 'Config Group',
    field: 'configGroup',
  },
  {
    display: 'Device Group',
    field: 'deviceGroup',
  },
  {
    display: 'Thing Group',
    field: 'thingGroup',
  },
  {
    display: 'Firmware Image',
    field: 'firmwareImage',
  },
  {
    display: 'Online',
    field: 'online',
    type: 'boolean',
  },
]

type OvenLookupFilterProps = {
  setSearchParams: (data: OvenLookupData) => void
  isLoading: boolean
  isPreviousData: boolean
}

const OvenLookupForm = ({
  setSearchParams,
  isLoading,
  isPreviousData,
}: OvenLookupFilterProps) => {
  return (
    <div
      className="bg-slate-50 shadow-lg max-w-96 p-4 rounded-lg h-max
xl:relative xl:top-[initial] xl:translate-y-[initial]
sticky top-4"
    >
      <form
        className="flex flex-col space-y-4"
        onSubmit={(e) => {
          e.preventDefault()

          const data: OvenLookupData = {}

          const form = e.target as HTMLFormElement
          const formInputs = form.querySelectorAll('input')
          let inputData
          for (const input of Array.from(formInputs)) {
            inputData = input.value
            inputData = inputData.trim()
            if (!inputData) {
              continue
            }
            data[input.id] = inputData
          }

          const formSelects = form.querySelectorAll('select')
          for (const select of Array.from(formSelects)) {
            inputData = select.value
            inputData = inputData.trim()
            if (!inputData) {
              continue
            }
            data[select.id] = inputData
          }

          console.log(data)

          if (!Object.keys(data).length) {
            return
          }

          setSearchParams(data)
        }}
      >
        <h2 className="text-xl font-bold">Oven Lookup</h2>

        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {OVEN_LOOKUP_FIELDS.map((field) => (
              <OvenLookupFilter
                key={field.field}
                field={field.field}
                name={field.display}
                type={field?.type || 'text'}
              />
            ))}
          </tbody>
        </table>

        <div className="italic text-gray-500 text-sm">
          Filter ovens by one or more of the properties above (use asterisk * as
          a wildcard)
        </div>

        <div className="flex flex-row justify-end">
          {!isLoading && !isPreviousData ? (
            <button
              className="bg-orange-1 text-white px-4 py-2 rounded-lg font-bold w-24 h-10"
              type="submit"
            >
              Search
            </button>
          ) : (
            <button
              className="bg-gray-400 text-white px-4 py-2 rounded-lg font-bold cursor-not-allowed w-24 h-10"
              disabled
            >
              <div className="flex justify-center">
                <CircleLoader />
              </div>
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

const OvenLookupFilter = (props: {
  name: string
  field: string
  value?: string
  type: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <tr>
      <td className="pb-4 pr-4">{props.name}</td>
      <td className="pb-4">
        {props.type === 'text' && (
          <input
            className="border border-grey-3 rounded-lg px-2 py-1"
            id={props.field}
            onChange={props.onChange}
            type="text"
            value={props.value}
          />
        )}

        {props.type === 'boolean' && (
          <select
            className="border border-grey-3 rounded-lg px-2 py-1"
            id={props.field}
            onChange={(e) => {
              if (!e || !props.onChange) {
                return
              }

              props.onChange(
                e as unknown as React.ChangeEvent<HTMLInputElement>,
              )
            }}
            value={props.value}
          >
            <option value="false">false</option>
            <option value="true">true</option>
          </select>
        )}
      </td>
    </tr>
  )
}

export default OvenLookupForm
