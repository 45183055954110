import { clsx } from 'clsx'

type Tab = {
  key: string
  label: string
  content: React.ReactNode
}

type Props = {
  tabs: Tab[]
  activeTab: string
  setActiveTab: (key: string) => void
}

const TabinatedRenderer = ({ tabs, activeTab, setActiveTab }: Props) => {
  return (
    <div className="shadow-sm rounded-md">
      <div className="flex flex-row w-full">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            className={clsx('px-2 py-1 rounded-md rounded-b-none', {
              'bg-slate-50 border border-b-0': activeTab !== tab.key,
              'bg-orange-1 text-white': activeTab === tab.key,
            })}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="p-2">
        <div>{tabs.find((tab) => tab.key === activeTab)?.content}</div>
      </div>
    </div>
  )
}

export default TabinatedRenderer
