import { ReactNode } from 'react'
import { QuantityStepper } from '@tovala/component-library'

type Props = {
  page: number
  rowsPerPage: number
  total?: number
  respectTotal?: boolean
  current?: number
  onChangePage: (page: number | ((n: number) => number)) => void
  onChangeRowsPerPage: (rowsPerPage: number | ((n: number) => number)) => void
  isLoading: boolean
  children?: ReactNode
  quantityOptions?: number[]
}

const defaultQuantities = [10, 25, 50, 100, 250]

const PaginationTableFooter = ({
  page,
  rowsPerPage,
  total,
  respectTotal,
  current,
  onChangePage,
  onChangeRowsPerPage,
  isLoading,
  children,
  quantityOptions = defaultQuantities,
}: Props): ReactNode => {
  return (
    <div>
      <div className="flex justify-end content-center items-center gap-4 m-2 flex-wrap">
        <div className="flex text-gray-500 align-middle gap-2 items-center mr-auto">
          <span>Page {page}</span>
          {total !== undefined && <span>{total} results</span>}
        </div>
        {children}
        <select
          className="border border-grey-3 rounded-lg px-2 py-1"
          onChange={(e) => {
            onChangePage(1)
            onChangeRowsPerPage(parseInt(e.target.value, 10))
          }}
          value={rowsPerPage}
        >
          {quantityOptions.map((quantity) => (
            <option key={quantity} value={quantity}>
              {quantity}
            </option>
          ))}
        </select>

        <QuantityStepper
          disabledDecrement={page === 1}
          disabledIncrement={
            !current ||
            current !== rowsPerPage ||
            isLoading ||
            (respectTotal && (total ?? Infinity) <= current * page)
          }
          min={1}
          onClickDecrement={() => {
            if (!current) {
              return
            }
            if (page === 1) {
              return
            }
            if (isLoading) {
              return
            }
            onChangePage((prev) => prev - 1)
          }}
          onClickIncrement={() => {
            if (!current) {
              return
            }
            if (current !== rowsPerPage) {
              return
            }
            if (isLoading) {
              return
            }
            if (respectTotal && (total ?? Infinity) <= current * page) {
              return
            }
            onChangePage((prev) => prev + 1)
          }}
          quantity={page}
          size={'small'}
        />
      </div>
    </div>
  )
}

export default PaginationTableFooter
