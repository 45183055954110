import { useQuery } from '@tanstack/react-query'
import { CaretDownIcon, CaretUpIcon } from '@tovala/component-library'
import { clsx } from 'clsx'
import JSONTable from 'components/common/JSONTable'
import PaginationTableFooter from 'components/common/PaginationTableFooter'
import { useState } from 'react'
import { getMostRecentThingEvents, JSONValue, OvenEvent } from 'utils/oatsApi'
import { convertToPrettyString } from 'utils/stringUtils'

const OvenEventViewer = ({ ovenId }: { ovenId: string }) => {
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { data, isLoading, isPreviousData, isError } = useQuery({
    queryKey: ['oven-events', ovenId, page, rowsPerPage],
    queryFn: async () => {
      if (!ovenId) {
        throw new Error('No thing name provided')
      }

      return await getMostRecentThingEvents(ovenId, page, rowsPerPage)
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  return (
    <div className="rounded-lg overflow-clip bg-slate-50 shadow-lg">
      <div className="overflow-auto">
        <table className="table-auto w-full">
          <thead className="bg-orange-1 text-white">
            <tr>
              <th className="p-2">Event Type</th>
              <th className="p-2">Event Key</th>
              <th className="p-2">Event Time</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              !isLoading &&
              !isError &&
              data.map((event: OvenEvent, indx) => (
                <OvenEventRow
                  key={event.receiveTimeMs}
                  event={event}
                  index={indx}
                />
              ))}
            {isLoading && (
              <tr>
                <td className="text-center" colSpan={4}>
                  Loading...
                </td>
              </tr>
            )}
            {isError && (
              <tr>
                <td className="text-center" colSpan={4}>
                  Error loading events
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <PaginationTableFooter
          current={data ? data.length : 0}
          isLoading={isLoading || isPreviousData}
          onChangePage={setPage}
          onChangeRowsPerPage={setRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </div>
  )
}

const OvenEventRow = ({
  event,
  index,
}: {
  event: OvenEvent
  index: number
}) => {
  const [dropdownState, setDropdownState] = useState<boolean>(false)

  if (!event.payload || Object.keys(event.payload).length === 0) {
    return (
      <tr
        className={clsx({
          'bg-slate-50': index % 2 === 0,
          'bg-white': index % 2 !== 0,
        })}
      >
        <td className="px-4 py-2">{event.eventType}</td>
        <td className="px-4 py-2">{convertToPrettyString(event.eventKey)}</td>
        <td className="px-4 py-2">
          {new Date(event.eventTimeMs).toLocaleString()}
        </td>
        <td></td>
      </tr>
    )
  }

  return (
    <>
      <tr
        className={clsx(
          'border-t border-b border-gray-200 px-4 py-2 w-full cursor-pointer',
          {
            'bg-slate-50': index % 2 === 0,
            'bg-white': index % 2 !== 0,
          },
        )}
        onClick={() => {
          setDropdownState(!dropdownState)
        }}
      >
        <td className="px-4 py-2">{event.eventType}</td>
        <td className="px-4 py-2">{convertToPrettyString(event.eventKey)}</td>
        <td className="px-4 py-2">
          {new Date(event.eventTimeMs).toLocaleString()}
        </td>
        <td className="px-4 py-2">
          <div className="w-4">
            {dropdownState ? <CaretDownIcon /> : <CaretUpIcon />}
          </div>
        </td>
      </tr>
      {dropdownState && (
        <tr
          className={clsx(
            'border-t border-b border-gray-200 px-4 py-2 w-full',
            {
              'bg-gray-50': index % 2 === 0,
              'bg-white': index % 2 !== 0,
            },
          )}
        >
          <td colSpan={4}>
            <div className="px-4 py-2">
              <JSONTable data={event.payload as JSONValue} />
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

export default OvenEventViewer
