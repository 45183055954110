import { useQuery } from '@tanstack/react-query'
import PaginationTableFooter from 'components/common/PaginationTableFooter'
import TabinatedRenderer from 'components/common/TabinatedRenderer'
import Table from 'components/common/Table'
import { useState } from 'react'
import { fetchFavorites, fetchHistory } from 'utils/oatsApi'

const OvenHistory = () => {
  const [historyPage, setHistoryPage] = useState<number>(1)
  const [historyPageSize, setHistoryPageSize] = useState<number>(10)

  const [favoritePage, setFavoritePage] = useState<number>(1)
  const [favoritePageSize, setFavoritePageSize] = useState<number>(10)
  const [globalFavorite, setGlobalFavorite] = useState<boolean>(false)

  const [activeTab, setActiveTab] = useState<string>('history')

  const { data: historyData, isLoading: historyLoading } = useQuery({
    queryKey: ['device-history', historyPage, historyPageSize],
    queryFn: async () => {
      return fetchHistory(historyPage, historyPageSize)
    },
  })

  const { data: allGlobalFavorites } = useQuery({
    queryKey: ['all-global-favorites'],
    queryFn: async () => {
      return fetchFavorites(1, 1000, true)
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  const { data: favoriteData, isLoading: favoriteLoading } = useQuery({
    queryKey: ['favorite-history', favoritePage, favoritePageSize],
    queryFn: async () => {
      return fetchFavorites(favoritePage, favoritePageSize, false)
    },
  })

  const allGlobalFavoritesObeyingPagination = allGlobalFavorites?.slice(
    (favoritePage - 1) * favoritePageSize,
    favoritePage * favoritePageSize,
  )

  return (
    <div>
      <div className="bg-slate-50 p-2 max-w-2xl shadow-lg rounded-md">
        <TabinatedRenderer
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={[
            {
              key: 'history',
              label: 'History',
              content: (
                <Table
                  columns={[
                    {
                      key: 'device_id',
                      title: 'Device ID',
                    },
                    {
                      key: 'name',
                      title: 'Name',
                    },
                    {
                      key: 'date',
                      title: 'Date',
                    },
                  ]}
                  data={
                    historyData?.map((item) => ({
                      device_id: (
                        <a
                          className="text-blue-400"
                          href={`/ovens/${item.deviceID}`}
                        >
                          {item.deviceID}
                        </a>
                      ),
                      name:
                        allGlobalFavorites?.find(
                          (a) => a.deviceID === item.deviceID,
                        ) && item.deviceID === item.name
                          ? allGlobalFavorites?.find(
                              (a) => a.deviceID === item.deviceID,
                            )?.name || item.name
                          : item.name,
                      date: new Date(
                        parseInt(item.viewedAtMs, 10),
                      ).toLocaleString(),
                    })) || []
                  }
                >
                  <PaginationTableFooter
                    isLoading={historyLoading}
                    onChangePage={setHistoryPage}
                    onChangeRowsPerPage={setHistoryPageSize}
                    page={historyPage}
                    rowsPerPage={historyPageSize}
                  />
                </Table>
              ),
            },
            {
              key: 'favorites',
              label: 'Favorites',
              content: (
                <Table
                  columns={[
                    {
                      key: 'device_id',
                      title: 'Device ID',
                    },
                    {
                      key: 'name',
                      title: 'Name',
                    },
                    {
                      key: 'date',
                      title: 'Date',
                    },
                  ]}
                  data={
                    (globalFavorite
                      ? allGlobalFavoritesObeyingPagination
                      : favoriteData
                    )?.map((item) => ({
                      device_id: (
                        <a
                          className="text-blue-400"
                          href={`/ovens/${item.deviceID}`}
                        >
                          {item.deviceID}
                        </a>
                      ),
                      name:
                        allGlobalFavorites?.find(
                          (a) => a.deviceID === item.deviceID,
                        ) && item.deviceID === item.name
                          ? allGlobalFavorites?.find(
                              (a) => a.deviceID === item.deviceID,
                            )?.name || item.name
                          : item.name,
                      date: new Date(
                        parseInt(item.viewedAtMs, 10),
                      ).toLocaleString(),
                    })) || []
                  }
                >
                  <PaginationTableFooter
                    isLoading={favoriteLoading}
                    onChangePage={setFavoritePage}
                    onChangeRowsPerPage={setFavoritePageSize}
                    page={favoritePage}
                    rowsPerPage={favoritePageSize}
                  >
                    <div className="flex items-center space-x-2">
                      <select
                        className="border border-grey-3 rounded-lg px-2 py-1"
                        onChange={(e) =>
                          setGlobalFavorite(e.target.value === 'true')
                        }
                        value={globalFavorite ? 'true' : 'false'}
                      >
                        <option value="false">My Favorites</option>
                        <option value="true">Global Favorites</option>
                      </select>
                    </div>
                  </PaginationTableFooter>
                </Table>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default OvenHistory
