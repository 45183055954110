import invariant from 'tiny-invariant'

type EnvVars = {
  APP_ENV: 'development' | 'develop-preview' | 'preview' | 'production' | 'test'
  APP_NAME: string
  CDN_URL: string
  FULLSTORY_ORG_ID: string
  JWT_COOKIE_NAME: string
  SEGMENT_WRITE_KEY: string
  SENTRY_DSN: string
  SENTRY_ORG: string
  VERCEL_GIT_COMMIT_SHA: string
  OATS_API_URL: string
}

const ALLOWED_APP_ENVS = [
  'development',
  'develop-preview',
  'preview',
  'production',
  'test',
] as const

function isAllowedAppEnv(appEnv: string): appEnv is EnvVars['APP_ENV'] {
  return ALLOWED_APP_ENVS.includes(appEnv as EnvVars['APP_ENV'])
}

invariant(
  import.meta.env.VITE_APP_ENV && isAllowedAppEnv(import.meta.env.VITE_APP_ENV),
  `APP_ENV must be one of: ${ALLOWED_APP_ENVS.join(', ')}`,
)
invariant(import.meta.env.VITE_APP_NAME, 'APP_NAME must be set')
invariant(import.meta.env.VITE_CDN_URL, 'CDN_URL must be set')
invariant(import.meta.env.VITE_FULLSTORY_ORG_ID, 'FULLSTORY_ORG_ID must be set')
invariant(import.meta.env.VITE_JWT_COOKIE_NAME, 'JWT_COOKIE_NAME must be set')
invariant(
  import.meta.env.VITE_SEGMENT_WRITE_KEY,
  'SEGMENT_WRITE_KEY must be set',
)

const ENV_VARS: { [key in keyof EnvVars]: EnvVars[key] } = {
  APP_ENV: import.meta.env.VITE_APP_ENV,
  APP_NAME: import.meta.env.VITE_APP_NAME,
  CDN_URL: import.meta.env.VITE_CDN_URL,
  FULLSTORY_ORG_ID: import.meta.env.VITE_FULLSTORY_ORG_ID,
  JWT_COOKIE_NAME: import.meta.env.VITE_JWT_COOKIE_NAME,
  SEGMENT_WRITE_KEY: import.meta.env.VITE_SEGMENT_WRITE_KEY,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? '',
  SENTRY_ORG: import.meta.env.VITE_SENTRY_ORG ?? '',
  VERCEL_GIT_COMMIT_SHA: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA ?? '',
  OATS_API_URL: import.meta.env.VITE_APP_OATS_API_URL ?? '',
}

export function getEnvVar<EnvVar extends keyof EnvVars>(
  envVar: EnvVar,
): EnvVars[EnvVar] {
  return ENV_VARS[envVar]
}
