const MAX_ARN_LENGTH = 2048
const MIN_ARN_LENGTH = 12
const ARN_INVALID_REGEX_TEST = /^[^a-zA-Z0-9:/+=,.@_*#-]*$/

interface Props {
  arn: string
  service: string
  region: string
  account: string
  remaining: string
}

type Result = string | null

const services: { [key: string]: (props: Props) => Result } = {
  iot: handleIoT,
  iam: handleIAM,
}

export function convertARNToURL(arn: string) {
  arn = arn.trim()

  if (!arn.startsWith('arn:aws:')) {
    console.warn('ARN does not start with arn:aws:')
    return null
  }

  if (arn.length > MAX_ARN_LENGTH) {
    console.warn('ARN is too long')
    return null
  }

  if (arn.length < MIN_ARN_LENGTH) {
    console.warn('ARN is too short')
    return null
  }

  if (ARN_INVALID_REGEX_TEST.test(arn)) {
    console.warn('ARN contains invalid characters')
    return null
  }

  const parts = arn.split(':')
  // const _arn = parts[0];
  // const _aws = parts[1];

  const service = parts[2]
  const region = parts?.[3] || 'us-west-2'
  const account = parts?.[4]

  const remaining = parts.slice(5).join(':')

  if (!services[service]) {
    console.warn('Service not supported')
    return null
  }

  return services[service]({
    arn,
    service,
    region,
    account,
    remaining,
  })
}

function handleIoT({ region, remaining }: Props): Result {
  const parts = remaining.split('/')
  const resourceType = parts[0]
  const resourceId = parts[1]

  return `https://${region}.console.aws.amazon.com/iot/home?region=${region}#/${
    resourceType
  }/${resourceId}`
}

function handleIAM({ region, remaining }: Props): Result {
  const parts = remaining.split('/')
  const resourceType = parts[0]
  const resourceId = parts[1]

  return `https://${region}.console.aws.amazon.com/iam/home?region=${region}#/${
    resourceType
  }s/details/${resourceId}`
}
