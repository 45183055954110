import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { useAuth } from 'contexts/auth'
import { LogoLockUpIcon } from './Icons'
import { getEnvVar } from 'utils/env'

const Header = () => {
  const { isLoggedIn } = useAuth()

  return (
    <header className="flex justify-between border-b border-grey-3 px-16 py-8 md:px-4 md:py-6">
      <Link to="/">
        <div className="h-4 w-32 text-black flex gap-2 items-end">
          {getEnvVar('APP_ENV') !== 'production' ? (
            <>
              {/* eslint-disable-next-line react/forbid-component-props */}
              <LogoLockUpIcon className="fill-orange-1" />
              <span className="text-xs text-orange-1">DEV</span>
            </>
          ) : (
            <LogoLockUpIcon />
          )}
        </div>
      </Link>

      {isLoggedIn && (
        <div className="flex items-center space-x-6">
          <HeaderNavLink to="/ovens">Ovens</HeaderNavLink>
          <HeaderNavLink to="/jobs">Jobs</HeaderNavLink>
          <HeaderNavLink to="/logout">Logout</HeaderNavLink>
        </div>
      )}
    </header>
  )
}

export default Header

const HeaderNavLink = ({
  children,
  to,
}: {
  children: ReactNode
  to: string
}) => {
  return (
    <NavLink
      className={({ isActive }) => {
        return clsx('flex items-center justify-center', {
          'text-orange-1': isActive,
        })
      }}
      to={to}
    >
      {children}
    </NavLink>
  )
}
