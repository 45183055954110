export function isCharUppercase(char: string) {
  return char === char.toUpperCase()
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function capitalizeFirstLetterOfEachWord(str: string) {
  return str.split(' ').map(capitalizeFirstLetter).join(' ')
}

/**
 * Convert programmer cases to pretty string
 * @example convertToPrettyString('camelCase') // 'Camel Case'
 * @example convertToPrettyString('snake_case') // 'Snake Case'
 * @example convertToPrettyString('kebab-case') // 'Kebab Case'
 * @example convertToPrettyString('PascalCase') // 'Pascal Case'
 * @example convertToPrettyString('UPPER_SNAKE_CASE') // 'UPPER SNAKE CASE'
 * @example convertToPrettyString('ACRONYMsWorkUnlessLowercase')
 * // 'ACRONYMs Work Unless Lowercase'
 * @example convertToPrettyString('IoT') // 'Io T'
 * // this is a limitation of the function
 * @param str
 * @returns
 */
export function convertToPrettyString(str: string) {
  if (typeof str !== 'string') {
    return convertToPrettyString(String(str))
  }
  if (!str) {
    return ''
  }
  const wordified = str.replace(/[_-]/g, ' ')

  const chars = wordified.split('')
  for (let i = 0; i < chars.length; i++) {
    if (i === 0) {
      continue
    }
    const char = chars[i]

    if (/[^a-zA-Z]/.test(char)) {
      continue
    }

    if (!isCharUppercase(char)) {
      continue
    }
    if (isCharUppercase(chars[i - 1])) {
      continue
    }
    chars[i] = ' ' + char
  }

  const prettyString = chars.join('')
  return capitalizeFirstLetterOfEachWord(prettyString)
}
