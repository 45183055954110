import JSONTable from 'components/common/JSONTable'
import { JSONValue, OvenData } from 'utils/oatsApi'

type Props = {
  oven?: OvenData
  includePageLink?: boolean
  error?: string
  loading?: boolean
}

const OvenDataViewer = ({ oven, includePageLink, error, loading }: Props) => {
  return (
    <div className="rounded-lg overflow-clip bg-slate-50 shadow-lg">
      <JSONTable
        data={
          {
            ...(oven &&
              !error &&
              !loading && {
                ...oven,
                ...(includePageLink && {
                  pageLink: `${window.location.origin}/ovens/${oven.thingName}`,
                }),
              }),
            ...(error && {
              error,
            }),
            ...(loading && {
              loading,
            }),
          } as JSONValue
        }
      />
    </div>
  )
}

export default OvenDataViewer
