import { Checkbox, Input, Modal, ModalHeader } from '@tovala/component-library'
import { clsx } from 'clsx'
import {
  sendOvenCommand,
  OvenCommands,
  OvenCommandEmpty,
  OvenCommandWithProps,
  OvenCommandParams,
  OvenCommandParamsTypes,
} from '../../utils/oatsApi'
import { useState } from 'react'

const buttonCommandGroups: {
  [key: string]: {
    prettyName: string
    commands: Partial<(keyof typeof OvenCommandParams)[]>
  }
} = {
  bake: {
    prettyName: 'Bake',
    commands: ['bake'],
  },
  ident: {
    prettyName: 'Identify',
    commands: ['flashAndBeep', 'partyRock'],
  },
  relays: {
    prettyName: 'Relays',
    commands: ['lamp', 'drain', 'convection', 'coolingFan'],
  },
  control: {
    prettyName: 'Control',
    commands: ['forceRestart', 'keyScanFlush', 'resetChips'],
  },
}

const OvenCommandsExecutor = ({ ovenId }: { ovenId: string }) => {
  const [cmd, setCmd] = useState<OvenCommands | null>(null)
  const [args, setArgs] = useState<Record<string, string | number | boolean>>(
    {},
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  return (
    <div className="rounded-lg shadow-lg overflow-clip p-2 bg-slate-50">
      <div className="p-4 text-lg font-bold">Oven Commands</div>
      <table>
        <tbody>
          {Object.entries(buttonCommandGroups).map(
            ([key, { prettyName, commands }], indx) => {
              return (
                <tr key={key}>
                  <td
                    className={clsx('p-4 text-md font-bold', {
                      'bg-gray-50': indx % 2 === 0,
                      'bg-white': indx % 2 !== 0,
                    })}
                    colSpan={2}
                  >
                    <h2>{prettyName}</h2>
                  </td>
                  <td>
                    <div className="flex gap-2 flex-wrap">
                      {commands.map((command) => {
                        return (
                          <div key={command}>
                            <button
                              className="text-white rounded-lg p-2 flex justify-center items-center bg-orange-1"
                              onClick={() => {
                                setCmd(command!)
                              }}
                            >
                              {
                                OvenCommandParams[
                                  command as keyof typeof OvenCommandParams
                                ].prettyName
                              }
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </td>
                </tr>
              )
            },
          )}
        </tbody>
      </table>
      {cmd && (
        <Modal
          onCloseModal={() => {
            setCmd(null)
            setArgs({})
            setError(null)
            setLoading(false)
          }}
        >
          <ModalHeader
            onClickClose={() => {
              setCmd(null)
              setArgs({})
              setError(null)
              setLoading(false)
            }}
          >
            <h1 className="text-xl font-bold">
              {
                OvenCommandParams[cmd as keyof typeof OvenCommandParams]
                  .prettyName
              }
            </h1>
          </ModalHeader>
          <div className="m-4 min-w-60">
            {Object.keys(
              OvenCommandParams[cmd as keyof typeof OvenCommandParams],
            ).filter((key) => key !== 'prettyName').length > 0 ? (
              <div className="flex flex-col gap-4">
                <div>
                  {Object.keys(
                    OvenCommandParams[cmd as keyof typeof OvenCommandParams],
                  )
                    .filter((key) => key !== 'prettyName')
                    .map((key) => {
                      const param = (
                        OvenCommandParams[
                          cmd as keyof typeof OvenCommandParams
                        ] as Record<string, OvenCommandParamsTypes>
                      )[key]

                      return (
                        <div key={key} className="flex flex-col gap-2">
                          <label htmlFor={key}>{param.prettyName}</label>
                          {param.type === 'string' && !param.options && (
                            <Input
                              id={key}
                              onChange={(e) => {
                                setArgs((prev) => ({
                                  ...prev,
                                  [key]: e.target.value,
                                }))
                              }}
                              value={(args[key] as string) || ''}
                            />
                          )}
                          {param.type === 'string' && param.options && (
                            <select
                              className="border border-grey-3 rounded-lg px-2 py-1"
                              id={key}
                              onChange={(e) => {
                                setArgs((prev) => ({
                                  ...prev,
                                  [key]: e.target.value,
                                }))
                              }}
                              value={(args[key] as string) || ''}
                            >
                              <option disabled selected value="">
                                Select an option
                              </option>
                              {param.options.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          )}
                          {param.type === 'number' && (
                            <Input
                              id={key}
                              onChange={(e) => {
                                setArgs((prev) => ({
                                  ...prev,
                                  [key]: Number(e.target.value),
                                }))
                              }}
                              value={(args[key] as number) || 0}
                            />
                          )}
                          {param.type === 'boolean' && (
                            <Checkbox
                              checked={args[key] as boolean}
                              id={key}
                              name={key}
                              onChange={(e) => {
                                setArgs((prev) => ({
                                  ...prev,
                                  [key]: e.target.checked,
                                }))
                              }}
                              type="checkbox"
                            />
                          )}
                        </div>
                      )
                    })}
                </div>
                {error && <div className="text-red text-sm">{error}</div>}
                <div className="flex flex-row justify-end">
                  <button
                    className={clsx('text-white rounded-lg p-2', {
                      // All args are filled
                      'bg-orange-1': Object.keys(
                        OvenCommandParams[
                          cmd as keyof typeof OvenCommandParams
                        ],
                      )
                        .filter((key) => key !== 'prettyName')
                        .every((key) => args[key] !== undefined),
                      // Not all args are filled
                      'bg-gray-400': !Object.keys(
                        OvenCommandParams[
                          cmd as keyof typeof OvenCommandParams
                        ],
                      )
                        .filter((key) => key !== 'prettyName')
                        .every((key) => args[key] !== undefined),
                    })}
                    disabled={loading}
                    onClick={async () => {
                      if (loading) {
                        return
                      }

                      if (
                        !Object.keys(
                          OvenCommandParams[
                            cmd as keyof typeof OvenCommandParams
                          ],
                        )
                          .filter((key) => key !== 'prettyName')
                          .every((key) => args[key] !== undefined)
                      ) {
                        setError('Please fill all fields')
                        return
                      }

                      setError(null)
                      setLoading(true)

                      try {
                        await sendOvenCommand(
                          ovenId,
                          cmd as OvenCommandWithProps,
                          // This is safe because we are checking if all fields are filled
                          // And the backend will validate everything as well
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          args as any,
                        )
                      } catch (e) {
                        if (e instanceof Error) {
                          setError(e.message)
                        } else {
                          setError('An error occurred')
                        }
                        setLoading(false)
                        return
                      }

                      setLoading(false)

                      setCmd(null)
                      setArgs({})
                    }}
                  >
                    Send Command
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-4">
                <div>
                  <p>Are you sure you want to send this command?</p>
                </div>
                {error && <div className="text-red text-sm">{error}</div>}
                <div className="flex flex-row justify-end">
                  <button
                    className="text-white rounded-lg p-2 bg-orange-1"
                    disabled={loading}
                    onClick={async () => {
                      if (loading) {
                        return
                      }

                      setError(null)
                      setLoading(true)

                      try {
                        await sendOvenCommand(ovenId, cmd as OvenCommandEmpty)
                      } catch (e) {
                        if (e instanceof Error) {
                          setError(e.message)
                        } else {
                          setError('An error occurred')
                        }
                        setLoading(false)
                        return
                      }

                      setLoading(false)

                      setCmd(null)
                      setArgs({})
                    }}
                  >
                    Send Command
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

export default OvenCommandsExecutor
