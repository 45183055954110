import { Tooltip } from '@tovala/component-library'
import { ReactNode } from 'react'

type Column = {
  key: string
  title: ReactNode
  width?: string
  tooltip?: boolean
}

type RowData = {
  [key: string]: ReactNode
}

type TableProps = {
  columns: Column[]
  data: RowData[]
  onRowClick?: (index: number, row: RowData) => void
  children?: ReactNode
}

const Table = ({ columns, data, onRowClick, children }: TableProps) => {
  const footer = children ? children : <></>

  return (
    <div className="overflow-clip rounded-lg">
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          <thead className="bg-orange-1 text-white">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  className={`p-2 px-4 text-md truncate ${column.width ? column.width : ''}`}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? ' bg-gray-50' : ' bg-white'}
              >
                {columns.map((column, index) => (
                  <td
                    key={column.key}
                    className={
                      'border-gray-200 px-4 py-2 ' +
                      (column.tooltip || onRowClick ? 'cursor-pointer' : '')
                    }
                    onClick={() => {
                      if (column.tooltip) {
                        return
                      }
                      if (onRowClick) {
                        onRowClick(index, row)
                      }
                    }}
                  >
                    <div
                      className={`truncate ${column.width ? column.width : ''}`}
                    >
                      {column.tooltip ? (
                        <Tooltip
                          trigger={
                            <div className="truncate">{row[column.key]}</div>
                          }
                        >
                          {row[column.key]}
                        </Tooltip>
                      ) : (
                        row[column.key]
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {footer}
    </div>
  )
}

export default Table
