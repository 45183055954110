import { useEffect, useRef, useState } from 'react'
import { getEnvVar } from 'utils/env'
import { tryGetDevToken } from 'utils/oatsApi'

import { FixedSizeList as List } from 'react-window'
import useWebsocket from 'utils/socketConnection'

const useDebugLogs = false

const logLevelColors = {
  E: 'text-red',
  W: 'text-yellow-600',
  I: 'text-green',
  D: 'text-blue-600',
  V: 'text-gray-500',
  WEB: 'text-purple-600',
}

type LogLevels = keyof typeof logLevelColors

type Log = {
  timestamp: number
  prefix: string
  message: string
  level: LogLevels
}

function parseLog(log: object): Log {
  if (Object.keys(log).length !== 2) {
    throw new Error(
      'Expected object with 2 keys, got object with ' +
        Object.keys(log).length +
        ' keys',
    )
  }

  const keys = Object.keys(log)
  if (!keys.includes('timestamp')) {
    throw new Error('Expected object with key "timestamp"')
  }

  if (!keys.includes('message')) {
    throw new Error('Expected object with key "message"')
  }

  const validated = log as Log

  const parts = validated.message.split(' ')
  if (parts.length < 2) {
    throw new Error('Expected message with at least 2 parts')
  }

  const level = parts[1] as LogLevels
  if (!['E', 'W', 'I', 'D', 'V'].includes(level)) {
    throw new Error('Invalid log level')
  }

  validated.level = level

  // Remove up to the log level
  validated.message = parts.slice(2).join(' ')

  const prefixMatch = validated.message.match(/^\(\d+\) [a-zA-Z-_0-9]+:/)
  if (prefixMatch) {
    validated.prefix = prefixMatch[0]
    validated.message = validated.message.substring(prefixMatch[0].length)
  } else {
    validated.prefix = ''
  }

  return validated
}

function parseLogs(log: string): Log[] {
  const parsed = JSON.parse(log)
  if (!Array.isArray(parsed)) {
    throw new Error('Expected array, got object')
  }

  const logs = parsed as object[]
  const parsedLogs = logs.map(parseLog)
  return parsedLogs
}

const OvenLogs = ({ ovenId }: { ovenId: string }) => {
  const { data: logs, setData: setLogs } = useWebsocket<Log>(
    `${getEnvVar('OATS_API_URL')}/api/oven/logs/${ovenId}/stream?token=${tryGetDevToken()}${
      useDebugLogs ? '&debug=true' : ''
    }`,
    parseLogs,
    {
      onConnectData: () => ({
        timestamp: Date.now(),
        message: 'Live Logs Connection opened',
        level: 'WEB',
        prefix: '',
      }),
      onDisconnectData: () => ({
        timestamp: Date.now(),
        message: 'Live Logs Connection closed',
        level: 'WEB',
        prefix: '',
      }),
    },
  )
  const [autoScroll, setAutoScroll] = useState(true)

  const listRef = useRef<List>(null)

  useEffect(() => {
    function scrollToBottom() {
      if (!listRef.current) {
        return
      }

      if (logs.length === 0) {
        return
      }

      if (!autoScroll) {
        return
      }

      listRef.current?.scrollToItem(logs.length)
    }
    scrollToBottom()
  }, [logs, autoScroll])

  return (
    <div className="flex flex-col space-y-4 w-full bg-slate-50 rounded-lg shadow-lg">
      <div className="relative overflow-hidden m-2">
        <List
          ref={listRef}
          // Prevents the list from jumping around when new logs are added that need a scrollbar
          // eslint-disable-next-line react/forbid-component-props
          className="overflow-scroll"
          height={400}
          itemCount={logs.length}
          itemSize={35}
          width={'100%'}
        >
          {({ index, style }) => (
            <div className="text-nowrap" style={style}>
              <span className="text-gray-400 font-mono">
                {new Date(logs[index].timestamp).toLocaleString()}{' '}
              </span>
              <span
                className={`${logLevelColors[logs[index].level]} font-mono`}
              >
                {logs[index].level} {logs[index].prefix}
              </span>
              <span className={logLevelColors[logs[index].level]}>
                {logs[index].message}
              </span>
            </div>
          )}
        </List>
        <div className="absolute bottom-4 right-6">
          <button
            className="bg-orange-1 text-white rounded-lg p-2 w-20 h-10 flex justify-center items-center"
            onClick={() => {
              setAutoScroll(!autoScroll)
            }}
          >
            {autoScroll ? 'Unlock' : 'Lock'}
          </button>
        </div>

        <div className="absolute top-0 right-6 w-36">
          <div
            className="flex flex-row text-gray-400 cursor-pointer"
            onClick={() => {
              setLogs([])
            }}
            title="Clear logs"
          >
            <div className="mr-auto">Total logs:</div>
            <div className="font-mono">{logs.length}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OvenLogs
