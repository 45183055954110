import ImagePublish from './ImagePublish'
import JobHistory from './JobHistory'

const JobsPage = () => {
  return (
    <div className="space-y-6 mb-32">
      <h1 className="text-k/44_110 font-medium md:text-k/36_110">Jobs</h1>
      <div className="flex flex-wrap gap-4 justify-evenly">
        <ImagePublish />
        <JobHistory />
      </div>
    </div>
  )
}

export default JobsPage
