import OvenHistory from './OvenHistory'
import OvensLookup from './OvensLookup'
import TestCodes from './TestCodes'

const OvensPage = () => {
  return (
    <div className="space-y-6 mb-32">
      <h1 className="text-k/44_110 font-medium md:text-k/36_110">Ovens</h1>
      <OvensLookup />
      <OvenHistory />
      <TestCodes />
    </div>
  )
}

export default OvensPage
