import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { OvenLookupData, searchForThings } from 'utils/oatsApi'
import OvenLookupForm from './OvenLookupForm'
import OvenLookupResult from './OvensLookupTable'
import OvenDataViewer from './OvenDataViewer'

const OvensLookup = () => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const [searchParams, setSearchParams] = useState<OvenLookupData | undefined>(
    undefined,
  )

  const queryClient = useQueryClient()

  const { isLoading, isError, data, error, isPreviousData } = useQuery({
    queryKey: ['ovens-lookup', { data: searchParams ?? {}, page, limit }],
    queryFn: searchForThings,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  return (
    <div className="flex justify-center">
      <div className="max-w-[83em] flex gap-4 justify-between xl:flex-wrap mb-4 xl:justify-around">
        <div>
          <OvenLookupForm
            isLoading={isLoading}
            isPreviousData={isPreviousData}
            setSearchParams={(v) => {
              setPage(1)
              setSearchParams(v)
            }}
          />
        </div>
        <div>
          {page === 1 && data?.ovens?.length === 1 ? (
            <OvenDataViewer includePageLink={true} oven={data.ovens[0]} />
          ) : (
            <OvenLookupResult
              data={data}
              error={error}
              invalidate={() => {
                queryClient.refetchQueries({
                  queryKey: [
                    'ovens-lookup',
                    { data: searchParams ?? {}, page, limit },
                  ],
                })
              }}
              isError={isError}
              isLoading={isLoading}
              isPreviousData={isPreviousData}
              limit={limit}
              page={page}
              setLimit={setLimit}
              setPage={setPage}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default OvensLookup
