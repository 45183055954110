import { useParams } from 'react-router-dom'
import {
  addFavorite,
  fetchFavorite,
  fetchThing,
  tryGetDevToken,
} from 'utils/oatsApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import OvenDataViewer from './OvenDataViewer'
import OvenEventViewer from './OvenEventViewer'
import OvenCommandsExecutor from './OvenCommands'
import OvenLogs from './OvenLogs'
import OvenEvents from './OvenEvents'
import ThingJobHistory from './OvenJobsViewer'
import Dropdown from 'components/common/Dropdown'
import { DropdownMenu, MenuItem } from 'components/common/DropdownMenu'
import {
  Checkbox,
  Input,
  MenuIcon,
  Modal,
  ModalHeader,
} from '@tovala/component-library'
import { clsx } from 'clsx'
import { useState } from 'react'
import EditThingsModal from './EditThingsModal'

const PreOvenPage = () => {
  const { ovenId } = useParams()

  if (!ovenId) {
    return <></>
  }

  return <OvenPage ovenId={ovenId} />
}

const OvenPage = ({ ovenId }: { ovenId: string }) => {
  const queryClient = useQueryClient()

  const [favoritesOpen, setFavoritesOpen] = useState(false)
  const [favoritesSelected, setFavoritesSelected] = useState<string>('local')

  const [editOpen, setEditOpen] = useState(false)

  const { data, isLoading, isError } = useQuery({
    queryKey: ['oven', ovenId],
    queryFn: async () => {
      const bearerToken = tryGetDevToken()
      return await fetchThing(bearerToken, ovenId)
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  const { data: favoritesData } = useQuery({
    queryKey: ['favorites', ovenId],
    queryFn: async () => {
      return await fetchFavorite(ovenId)
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  const myFavorite = favoritesData?.find((f) => f.user !== '0')
  const universalFavorite = favoritesData?.find((f) => f.user === '0')

  const [myFavoriteName, setMyFavoriteName] = useState(myFavorite?.name)
  const [myFavoriteFavorited, setMyFavoriteFavorited] = useState(
    myFavorite?.favorited,
  )
  const [universalFavoriteName, setUniversalFavoriteName] = useState(
    universalFavorite?.name,
  )
  const [universalFavoriteFavorited, setUniversalFavoriteFavorited] = useState(
    universalFavorite?.favorited,
  )

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="flex text-k/44_110 font-medium md:text-k/36_110 flex-1 gap-2">
          <span>Oven</span>
          {myFavorite && myFavorite?.name !== myFavorite?.deviceID ? (
            <div className="flex w-max items-center gap-2">
              <div
                className={clsx({
                  'text-orange-1': myFavorite.favorited,
                })}
              >
                {myFavorite.name}
              </div>
              <div className="text-k/20_110"> - {ovenId}</div>
            </div>
          ) : (
            ovenId
          )}
          {universalFavorite &&
            universalFavorite?.name !== universalFavorite?.deviceID && (
              <div className="flex w-max items-center gap-2 text-k/20_110">
                <span className="text-k/20_110"> - Global: </span>
                <div
                  className={clsx({
                    'text-orange-1': universalFavorite.favorited,
                  })}
                >
                  {universalFavorite.name}
                </div>
              </div>
            )}
        </h1>
        <div>
          <Dropdown
            trigger={
              <button className="btn btn-primary w-6 h-6">
                <MenuIcon />
              </button>
            }
          >
            <DropdownMenu>
              <MenuItem
                key={'favorite'}
                label={'Favorite'}
                onClick={() => {
                  setMyFavoriteName(myFavorite?.name)
                  setMyFavoriteFavorited(myFavorite?.favorited)
                  setUniversalFavoriteName(universalFavorite?.name)
                  setUniversalFavoriteFavorited(universalFavorite?.favorited)
                  setFavoritesOpen(true)
                }}
              />
              <MenuItem
                key={'edit'}
                label={'Edit'}
                onClick={() => {
                  setEditOpen(true)
                }}
              />
            </DropdownMenu>
          </Dropdown>
          {favoritesOpen && (
            <Modal onCloseModal={() => setFavoritesOpen(false)}>
              <ModalHeader onClickClose={() => setFavoritesOpen(false)}>
                <h1 className="text-xl font-bold">Favorite Device</h1>
              </ModalHeader>
              <div className="p-2">
                <div className="min-w-64 m-2">
                  <div>
                    {/* local or global */}
                    <div className="text-lg font-bold">Scope</div>
                    <select
                      className="border border-grey-3 rounded-lg px-2 py-1"
                      id="scope"
                      name="scope"
                      onChange={(e) => {
                        setFavoritesSelected(e.target.value)
                      }}
                      value={favoritesSelected}
                    >
                      <option value="local">My Favorite</option>
                      <option value="universal">Global Favorite</option>
                    </select>
                  </div>
                  {/* name */}
                  <div>
                    <div className="text-lg font-bold">Name</div>
                    <Input
                      onChange={(e) => {
                        if (favoritesSelected === 'local') {
                          setMyFavoriteName(e.target.value)
                        } else {
                          setUniversalFavoriteName(e.target.value)
                        }
                      }}
                      value={
                        favoritesSelected === 'local'
                          ? myFavoriteName
                          : universalFavoriteName
                      }
                    />
                  </div>
                  {/* favorited */}
                  <div>
                    <div className="text-lg font-bold">Favorited</div>
                    <Checkbox
                      checked={
                        favoritesSelected === 'local'
                          ? myFavoriteFavorited
                          : universalFavoriteFavorited
                      }
                      name={'favorited'}
                      onChange={(e) => {
                        if (favoritesSelected === 'local') {
                          setMyFavoriteFavorited(e.target.checked)
                        } else {
                          setUniversalFavoriteFavorited(e.target.checked)
                        }
                      }}
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="bg-orange-1 text-white rounded-lg p-2 w-20 h-10 flex justify-center items-center"
                      onClick={async () => {
                        setFavoritesOpen(false)
                        const name =
                          favoritesSelected === 'local'
                            ? myFavoriteName
                            : universalFavoriteName
                        const favorited =
                          favoritesSelected === 'local'
                            ? myFavoriteFavorited
                            : universalFavoriteFavorited
                        if (name === undefined) {
                          return
                        }
                        if (favorited === undefined) {
                          return
                        }
                        await addFavorite(
                          ovenId,
                          name,
                          favorited,
                          favoritesSelected === 'universal',
                        )
                        await queryClient.refetchQueries(['favorites', ovenId])
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>

      <div className="flex flex-row justify-around flex-wrap gap-4">
        <div className="flex flex-col space-y-4">
          <OvenDataViewer
            error={isError ? 'Error loading oven' : undefined}
            loading={isLoading}
            oven={data}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <OvenCommandsExecutor ovenId={ovenId} />
        </div>
        <div className="flex flex-col space-y-4 min-w-96">
          <OvenEventViewer ovenId={ovenId} />
        </div>
        <div className="flex flex-col space-y-4 min-w-96">
          <ThingJobHistory ovenId={ovenId} />
        </div>
        <div className="flex-col space-y-4 min-w-96 w-full">
          <OvenLogs ovenId={ovenId} />
        </div>
        <div className="flex-col space-y-4 min-w-96 w-full">
          <OvenEvents ovenId={ovenId} />
        </div>
      </div>
      {data && editOpen && (
        <EditThingsModal
          invalidate={() => {
            queryClient.refetchQueries(['oven', ovenId])
          }}
          selected={[data]}
          setEditOpen={setEditOpen}
        />
      )}
    </div>
  )
}

export default PreOvenPage
