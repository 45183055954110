import { useEffect, useState } from 'react'

export type WebsocketHookOptions<T> = {
  onConnectData?: () => T
  onDisconnectData?: () => T
  clearDataOnConnect?: boolean
}

function useWebsocket<T>(
  url: string,
  parser: (data: string) => T[],
  options?: WebsocketHookOptions<T>,
) {
  const [data, setData] = useState<T[]>([])
  const [reconnect, setReconnect] = useState(true)
  const [connected, setConnected] = useState(false)

  useEffect(() => {
    let ws: WebSocket | null = null

    function connect() {
      if (!reconnect) {
        return
      }

      ws = new WebSocket(url)

      ws.onmessage = (event) => {
        try {
          const parsedData = parser(event.data)
          setData((prevData) => [...(prevData ?? []), ...parsedData])
        } catch (error) {
          console.error('Failed to parse data:', error)
        }
      }

      ws.onopen = () => {
        console.log('Connection opened')
        if (options?.clearDataOnConnect) {
          setData([])
        }
        if (options?.onConnectData) {
          setData((prevData) => [...(prevData ?? []), options.onConnectData!()])
        }
        setConnected(true)
      }

      ws.onclose = () => {
        console.log('Connection closed')
        if (options?.onDisconnectData) {
          setData((prevData) => [
            ...(prevData ?? []),
            options.onDisconnectData!(),
          ])
        }
        setConnected(false)
        setTimeout(connect, 1000)
      }
    }

    connect()

    return () => {
      if (!ws) {
        return
      }

      setConnected(false)

      ws.close()
      ws = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    url,
    parser,
    setData,
    setConnected,
    reconnect,
    options?.clearDataOnConnect,
  ])

  return {
    data,
    setData,
    setReconnect,
    connected,
  }
}

export default useWebsocket
